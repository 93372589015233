'use strict';

module.exports ={
    updateCanonicalURL: function (el) {
        var canonical = el.html().replace(/&amp;/g, '&');
        var $canonicalTag = $('link[rel="canonical"]');

        if ($canonicalTag.length) {
            $canonicalTag.attr('href', canonical);
        } else {
            var $canonicalTagExample = '<link rel="canonical" href="' + canonical + '"/>';
            $('head').append($canonicalTagExample);
        }

    }
}