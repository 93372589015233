'use strict';

var Search = require('core/search/search');
var commonHelper = require('../helpers/common');

var isMobile;

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
 function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-sort',
        '.mobile-sort-trigger',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.secondary-bar .result-count',
        '.js-canonical-url'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * Set Variable for Mobile logic
 */
function checkBreakpoint() {
    if (window.matchMedia('(max-width: 62em)').matches) {
        isMobile = true;
    } else {
        isMobile = false;
    }
}

/**
 * Align tile elements
 */
function syncTiles() {
    var $productTile = $('.product-grid .product');

    if ($productTile.length === 0) { return; }

    var tilesPerRow = 2;
    var totalTiles = $productTile.length;
    var totalRows = Math.ceil(totalTiles / tilesPerRow);
    var tileCount = 1;
    var rowCount = 1;
    var rowNum = 1;

    // Redefine variable for desktop
    if (window.matchMedia('(min-width: 544px)').matches) {
        tilesPerRow = 3;
    }

    // Add same class to all tiles in a row
    $productTile.each(function () {
        // Add row data attribute to each tile per row
        $(this).attr('data-row', 'row' + rowNum);

        if (tileCount % tilesPerRow === 0) { // check if a new row is starting
            rowNum++; // increase row number
        }
        tileCount++; // increase tile count
    });

    while (rowCount <= totalRows) {
        $('.product-grid .product[data-row="row' + rowCount + '"]').find('.product-tile__family-name').matchHeight();
        $('.product-grid .product[data-row="row' + rowCount + '"]').find('.product-tile__main').matchHeight();
        // $('.product-grid .product[data-row="row' + rowCount + '"]').find('.product-tile__name').matchHeight();
        // $('.product-grid .product[data-row="row' + rowCount + '"]').find('.tile-body').matchHeight();
        // $('.product-grid .product[data-row="row' + rowCount + '"]').matchHeight();
        rowCount++;
    }
}

/**
 * Dismiss Refinement pane
 */
function dismissRefinements() {
    $('body').removeClass('filter-active');
    $('.refinement-bar').removeClass('show').addClass('hidden');
    $('.grid-wrapper').parents('.grid-main').removeClass('refinements-active');
    $('.modal-background').hide();
    $('.refinement-bar').siblings().attr('aria-hidden', false);
    $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
    $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
    $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
    $('.btn.filter-results').focus();
    $('.grid-header .hide-filters').hide();
    $('.grid-header .show-filters').show();
}

/**
 * Open Refinement pane
 */
function openRefinements() {
    $('body').addClass('filter-active');
    $('.refinement-bar').removeClass('hidden').addClass('show');
    $('.grid-wrapper').parents('.grid-main').addClass('refinements-active');
    if (isMobile) {
        $('.modal-background').show();
    }
    $('.refinement-bar').siblings().attr('aria-hidden', true);
    $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
    $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
    $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
    $('.refinement-bar .close').focus();
    $('.grid-header .show-filters').hide();
    $('.grid-header .hide-filters').show();
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
 function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * Opening refinements block with active filters
 */
function openRefinementsBlocks() {
    var refinementsElements = $('.refinements .checkbox-selector, .refinements .custom-control-input, .refinements .radio-selector');
    $.each(refinementsElements, function () {
        var $el = $(this);
        var isActive = $el.is('.selected');

        if (isActive) {
            $el.closest('.collapse').collapse('show');
        }
    });
}

Search.initHistory = function () {
    let previousHistoryState;
    let doNotPushHistory = false;

    /**
     * Listen to all possible AJAX calls on a search page (Filters, sorting, show more, ...).
     */
    $(document).ajaxSuccess(
        function (event, xhr) {
            // Make the assumption that the existence of data attribute means  being present in the response means a grid-refresh
            if (xhr.responseText.indexOf('data-titlereturn="true"') >= 0) {
                if (!doNotPushHistory) {
                    setTimeout(function () {
                        history.pushState({ reapplyFilters: true }, document.title, decodeURI($('.permalink').val()));
                    });
                }

                doNotPushHistory = false;
            }
        }
    );

    /**
     * Listen to the back and forward button of the browser to detect URL changes.
     */
    window.addEventListener('popstate', () => {
        if ((history.state && history.state.reapplyFilters)
            || (previousHistoryState && previousHistoryState.reapplyFilters)) {
            const $resetButton = $('.refinement-bar button.reset');

            previousHistoryState = history.state;

            // This async call should not cause a new history state to be pushed.
            doNotPushHistory = true;

            // Use the reset button listeners to do a refresh
            $resetButton.data('href', decodeURI(window.location.href));
            $resetButton.trigger('click');
        }
    });
};

Search.init = function () {
    if (!isMobile && $('.grid-main').hasClass('has-refinements')) {
        if (!$('.refinement-bar').hasClass('show')) {
            openRefinements();
        }
    }
    syncTiles();
};

// remove old filter function to remove event delegation
delete Search.filter;

Search.filter = function () {
    $('.refinement-bar').on("animationend", function () {
        syncTiles();
    });
    // Display refinements bar when Menu icon clicked
    $('.grid-header').on('click', 'button.filter-results', function () {
        if ($('.refinement-bar').hasClass('show')) {
            dismissRefinements();
        } else {
            openRefinements();
        }
    });
};

Search.sort = function () {
    // Handle sort order menu selection
    $('.container').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:sort', this.value);
        $.ajax({
            url: this.value,
            data: { selectedUrl: this.value },
            method: 'GET',
            success: function (response) {
                $('.product-grid').empty().html(response);
                syncTiles();
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('.mobile-sort-trigger .sort-by-label').on('click', function () {
        $('.grid-sort').toggleClass('js-show');
    });
};

Search.closeRefinements = function () {
    // Refinements close button
    $('.container').on('click', '.refinement-bar button.close, .modal-background, .refinements-footer .apply-filters', function () {
        dismissRefinements();
        syncTiles();
    });
};

Search.mobileResetRefinements = function () {
    $('.refinements-footer').on('click', '.reset-filters', function () {
        if (isMobile) {
            $('.refinement-bar button.reset').trigger('click');
            dismissRefinements();
            syncTiles();
        }
    });
};

Search.resize = function () {
    checkBreakpoint();
    var currBreakpoint = isMobile ? 'mobile' : 'desktop';

    // Close refinement bar and hide modal background if user resizes browser
    $(window).smartresize(function () {
        checkBreakpoint();
        var newBreakpoint = isMobile ? 'mobile' : 'desktop';

        if (newBreakpoint === 'desktop') {
            $('.modal-background').hide();
        }

        if (newBreakpoint !== currBreakpoint) {
            currBreakpoint = newBreakpoint;

            if(newBreakpoint !== 'desktop') {
                dismissRefinements();
            } else {
                openRefinements();
            }
        }
        syncTiles();
    });
};

Search.applyFilter = function () {
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button',
        function (e) {
            e.preventDefault();
            e.stopPropagation();

            $.spinner().start();
            $(this).trigger('search:filter', e);
            $.ajax({
                url: $(this).data('href'),
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: $(this).data('href')
                },
                method: 'GET',
                success: function (response) {
                    parseResults(response);
                    openRefinementsBlocks();

                    var canonical = $('.js-canonical-url').html().replace(/&amp;/g, '&');
                    $('link[rel="canonical"]').attr('href', canonical);
                    commonHelper.updateCanonicalURL($('.js-canonical-url'));
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
};

Search.showMore = function () {
    // Show more products
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        var showMoreUrl = $(this).data('url');
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:showMore', e);
        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                $('.grid-footer').replaceWith(response);
                updateSortOptions(response);
                commonHelper.updateCanonicalURL($('.js-canonical-url').last());
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

module.exports = Search;
